import React from 'react';
import { ReactComponent as HeartIcon } from '../../../assets/img/heart.svg';
import {ReactComponent as SuperdevLogo} from "../../../assets/img/superdev.svg";

export const Footer = () => {
  return (
    <footer className='text-center mt-2 mb-3'>
      <div>
        <a
          {...{
            target: '_blank'
          }}
          className='d-flex align-items-center'
          href='https://superdev.ro/'
        >
          {/*Made with <HeartIcon className='mx-1' /> by the MultiversX team*/}
          Made by <SuperdevLogo className='superdev-logo' /> team
        </a>
      </div>
    </footer>
  );
};
