import { createSlice } from '@reduxjs/toolkit'
import axios from "axios"

function setForm () {
  return {
    promptStrength: 7,
    imageStrength: 0.4,
    samples: 1,
    style: '',
    prompt: '',
    isPaying: false
  }
}

const initialState = {
  formData: null,
  form: setForm(),
  images: [],
  image: '',
  fetching: false,
  canvas: undefined
}

export const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    SET_STATE (state, { payload: { key, value } }) {
      state[key] = value
    },
    SET_FORM (state, { payload: { key, value } }) {
      state.form[key] = value
    },
    APPEND_FORM_DATA (state, {payload: {key, value}}) {
      state.formData.delete(key)
      state.formData.append(key, value)
    },
    REMOVE_ITEM_FORM_DATA (state, {payload}) {
      state.formData.delete(payload)
    },
    INIT_FORM_DATA (state) {
      const image = localStorage.getItem('image')
      const images = localStorage.getItem('images')
      const form = localStorage.getItem('form')
      state.image = image
      state.images = images ? JSON.parse(images) : []
      state.form = form ? JSON.parse(form) : setForm()
      state.formData = new FormData()
      if (image) {
        const file = dataURLtoFile(`data:image/png;base64,${image}`,'image.png');
        state.formData.append('image', file);
      }
      Object.keys(state.form).forEach(key => {
        // @ts-ignore
        state.formData.append(key, state.form[key])
      })
    },
    STORE_FORM_DATA (state) {
      const formString = JSON.stringify(state.form);
      localStorage.setItem('form', formString);
      const file = state.formData.get('image');
      if (file) {
        // Create a FileReader object to read the file as a data URL
        const reader = new FileReader();
        reader.readAsDataURL(file);
        // When the FileReader finishes reading the file, encode the data URL as base64 and store it in localStorage
        reader.onload = function() {
          const fileData = reader.result;
          const base64Data = fileData.split(',')[1]; // Extract only the base64-encoded data from the data URL
          localStorage.setItem('image', base64Data);
        }
      }
    },
    STORE_IMAGES (state, {payload}) {
      localStorage.setItem('images', JSON.stringify(payload))
    },
    RESET_DATA (state) {
      state.form = setForm()
      state.formData = new FormData()
      Object.keys(state.form).forEach(key => {
        // @ts-ignore
        state.formData.append(key, state.form[key])
      })
    }
  }
})

export const { RESET_DATA, STORE_IMAGES, SET_STATE, STORE_FORM_DATA, SET_FORM, APPEND_FORM_DATA, INIT_FORM_DATA, REMOVE_ITEM_FORM_DATA } = imageSlice.actions

export const generate = function ({transactions}) {
  return async function (dispatch, getState) {
    const { image: { formData } } = getState()
    try {
      dispatch(SET_STATE({key: 'fetching', value: true}));
      const endpoint = formData.get('image') ? 'img2img' : 'txt2img'
      dispatch(SET_STATE({key: 'address', value: transactions.sender}));
      dispatch(APPEND_FORM_DATA({key: 'transactions', value: JSON.stringify(transactions)}));
      const { data } = await axios.post(`/api/generate/${endpoint}`, formData)
      dispatch(STORE_IMAGES(data));
      dispatch(RESET_DATA())
      dispatch(SET_STATE({key: 'fetching', value: false}));
      dispatch(SET_STATE({key: 'images', value: data}));
      dispatch(SET_STATE({key: 'image', value: null}));
      dispatch(SET_STATE({key: 'isPaying', value: false}));
      clearSessionStorage()
    } catch (err) {
      dispatch(SET_STATE({key: 'fetching', value: false}));
      dispatch(RESET_DATA())
      console.log(err.response ? err.response?.data : err);
    }
  }
}

export const get_images = function ({address}) {
  return async function (dispatch) {
    try {
      const { data } = await axios.get(`/api/images/address/${address}`)
      dispatch(SET_STATE({key: 'images', value: data}));
      console.log(data)
    } catch (err) {
      dispatch(SET_STATE({key: 'fetching', value: false}));
      dispatch(RESET_DATA())
      console.log(err.response ? err.response?.data : err);
    }
  }
}

function dataURLtoFile(dataurl, filename) {

  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, {type:mime});
}

function clearSessionStorage () {
  localStorage.removeItem('sessionID');
  localStorage.removeItem('image');
  localStorage.removeItem('form');
}

export default imageSlice.reducer
