import * as React from 'react';
import { Link } from 'react-router-dom';
import { AuthRedirectWrapper } from 'components';
import { dAppName } from 'config';
import { routeNames } from 'routes';
import './css/home-page.scss';
import {useEffect} from "react";
import {useSelector} from "react-redux";

const HomePage = () => {
  // @ts-ignore
  const canvas = useSelector(state => state.image.canvas)
  let height = 0

  useEffect(() => {
    if (canvas) {
      document.addEventListener('scroll', handleScroll, false)
      window.addEventListener('resize', handleResize, false)
      height = document.body.offsetHeight - window.innerHeight
    }
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [canvas])

  function handleResize () {
    const windowHeight = window.innerHeight;
    let top;
    if (windowHeight > 805) {
      top = (windowHeight - 805) / 2.6
      canvas.style.top = `${-top}px`
    } else {
      top = (805 - windowHeight) / 2.2
      canvas.style.top = `${top}px`
    }
  }

  function handleScroll () {
    canvas.style.top = `${(height - window.scrollY) - height + 50}px`
  }

  return (
    <div className='home-page'>
      <div>
        <h1 className='mb-3' data-testid='title'>
          <span>{dAppName}</span>
          <br/>
          Image generator web app
        </h1>

        <p>
          We have designed a dedicated app to generate images from an
          initial image or from text. <br/>
          Using your MultiversX (Elrond)
          account, you can easily enter the app and generate your
          own custom images.
        </p>

        <Link
          to={routeNames.unlock}
          className='btn btn-primary mt-3 text-white'
          data-testid='loginBtn'
        >
          Login
        </Link>
      </div>

      <section>
        <h2>How to use this app on mobile</h2>
        <div>
          <h3>
            1. Get the xPortal app from &nbsp;
            <a href="https://xport.al/app">here</a>
          </h3>

          <h3>
            2. Purchase EGLD &nbsp;
          </h3>
          <p>You can easily purchase EGLD
            with a card or bank transfer.</p>

          <h3>
            3. Login and create your own images
          </h3>
        </div>


      </section>
    </div>
  );
};

export const Home = () => (
  <AuthRedirectWrapper>
    <HomePage />
  </AuthRedirectWrapper>
);
