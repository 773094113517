import { useTrackTransactionStatus } from '../hooks';
import {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import Lottie from 'lottie-react';
import {generate, SET_STATE} from "../store/modules/image"
import Loading from '../assets/lottie/loading.json';

export const LoadingSpinner = function ({ sessionID }) {
  const dispatch = useDispatch();
  const { fetching } = useSelector(state => state.image)
  const { isPending, isSuccessful, status, transactions } =
    useTrackTransactionStatus({
      transactionId: sessionID
    });

  useEffect(() => {
    if (isPending) {
      dispatch(SET_STATE({key: 'isPaying', value: true}));
    }
    if (isSuccessful) {
      dispatch(generate({transactions}));
    }
  }, [isPending, isSuccessful]);
  return (
    <div className='lottie-img-loading'>
      {fetching && <Lottie animationData={Loading} loop={true} />}
    </div>
  );
};
