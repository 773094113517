export const contractAddress =
  'erd19px0duhk6zhrepd0pv8lghme3gfydufkfjzg0fte3ke4pw7fs34qrwkmwd';

export const dAppName = 'Dobi AI';

// Generate your own WalletConnect 2 ProjectId here: https://cloud.walletconnect.com/app
export const walletConnectV2ProjectId = '6225f314d86c5794db933d54d50dbdde';

export const apiTimeout = 6000;
export const transactionSize = 15;
export const TOOLS_API_URL = 'https://tools.multiversx.com';
/**
 * Calls to these domains will use `nativeAuth` Baerer token
 */
export const sampleAuthenticatedDomains = [TOOLS_API_URL];
