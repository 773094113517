import { texture } from './loader'
import { THREE, lerp } from './utils'
import fragment from './shaders/fragment.glsl'
import fragmentPoints from './shaders/fragmentPoints.glsl'
import vertex from './shaders/vertex.glsl'
import vertexPoints from './shaders/vertexPoints.glsl'
import { gsap } from 'gsap'

let camera,
    scene,
    renderer,
    canvas,
    points,
    clock,
    raycaster,
    pointer,
    group,
    plane_mesh,
    galaxy_mesh

let progress = { value: 1 }
let time = 0
let materials = []
let options = [
  {
    min_radius: 1,
    max_radius: 3,
    color: '#212121',
    size: 0.35
  },
  {
    min_radius: 1,
    max_radius: 3,
    color: '#000000',
    size: 0.25
  }
]

function init(canvas_id) {
  // NOTE: Specify a canvas which is already created in the HTML.
  canvas = document.getElementById(canvas_id)
  // NOTE: Camera params.
  let fov = 75
  let near_plane = 0.001
  let far_plane = 3000

  scene = new THREE.Scene()

  camera = new THREE.PerspectiveCamera(
    fov,
    canvas.offsetWidth / canvas.offsetHeight,
    near_plane,
    far_plane
  )

  if (window.innerWidth <= 786) camera.position.set(0, 0, 5)
  else camera.position.set(0, 0, 3.5)

  clock = new THREE.Clock()
  raycaster = new THREE.Raycaster()
  pointer = new THREE.Vector2()

  renderer = new THREE.WebGLRenderer({
    canvas,
    antialias: true,
    alpha: true
  })

  renderer.outputEncoding = THREE.sRGBEncoding  // NOTE: Comment if we need bloomPass
  renderer.setPixelRatio(window.devicePixelRatio)
  renderer.setSize(canvas.offsetWidth, canvas.offsetHeight)
  renderer.toneMapping = THREE.ACESFilmicToneMapping
  renderer.toneMappingExposure = 1
  renderer.setClearColor( 0xffffff, 1)

  group = new THREE.Group


  window.addEventListener('resize', () => onWindowResize(), false)
  window.addEventListener( 'pointermove', on_pointer_move )


  display_globe()

  options.forEach(opts => {
    display_model(opts)
  })
  add_plane()
  add_background()
  create_object_on_load()
}

function add_background () {
  const particle_geometry = new THREE.BufferGeometry()
  const particles_count = 5000

  const pos_arr = new Float32Array(particles_count * 3)

  const particle_material = new THREE.PointsMaterial({
    size: 0.0025,
    color: 0x212121,
  })
  for (let i = 0; i < particles_count * 3; i++) {
    pos_arr[i] = (Math.random() - 0.5) * 7
  }

  particle_geometry.setAttribute('position', new THREE.BufferAttribute(pos_arr, 3))

  galaxy_mesh = new THREE.Points(particle_geometry, particle_material)

  scene.add(galaxy_mesh)
}

function display_globe () {
    const circle = new THREE.IcosahedronGeometry(0.75, 20)
    // const circle = new THREE.SphereGeometry( 0.75, 80, 50 )
    const circle_material = new THREE.ShaderMaterial({
      side: THREE.DoubleSide,
      uniforms: {
        time: { type: 'f', value: 0 },
        progress: { type: 'f', value: 0 },
      },
      vertexShader: `uniform float time;
uniform float progress;
uniform vec3 pointerPos;
varying vec2 vUv;
varying vec3 vPosition;
varying vec3 vNormal;
varying vec3 vWorldPosition;

float PI = 3.141552653589793238;


vec3 mod289(vec3 x) {
  return x - floor(x * (1.0 / 289.0)) * 289.0;
}

vec4 mod289(vec4 x) {
  return x - floor(x * (1.0 / 289.0)) * 289.0;
}

vec4 permute(vec4 x) {
  return mod289(((x*34.0)+1.0)*x);
}

vec4 taylorInvSqrt(vec4 r)
{
  return 1.79284291400159 - 0.85373472095314 * r;
}

float snoise(vec3 v)
{
  const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
  const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);

  // First corner
  vec3 i  = floor(v + dot(v, C.yyy) );
  vec3 x0 =   v - i + dot(i, C.xxx) ;

  // Other corners
  vec3 g = step(x0.yzx, x0.xyz);
  vec3 l = 1.0 - g;
  vec3 i1 = min( g.xyz, l.zxy );
  vec3 i2 = max( g.xyz, l.zxy );

  //   x0 = x0 - 0.0 + 0.0 * C.xxx;
  //   x1 = x0 - i1  + 1.0 * C.xxx;
  //   x2 = x0 - i2  + 2.0 * C.xxx;
  //   x3 = x0 - 1.0 + 3.0 * C.xxx;
  vec3 x1 = x0 - i1 + C.xxx;
  vec3 x2 = x0 - i2 + C.yyy; // 2.0*C.x = 1/3 = C.y
  vec3 x3 = x0 - D.yyy;      // -1.0+3.0*C.x = -0.5 = -D.y

  // Permutations
  i = mod289(i);
  vec4 p = permute( permute( permute(
  i.z + vec4(0.0, i1.z, i2.z, 1.0 ))
  + i.y + vec4(0.0, i1.y, i2.y, 1.0 ))
  + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));

  // Gradients: 7x7 points over a square, mapped onto an octahedron.
  // The ring size 17*17 = 289 is close to a multiple of 49 (49*6 = 294)
  float n_ = 0.142857142857; // 1.0/7.0
  vec3  ns = n_ * D.wyz - D.xzx;

  vec4 j = p - 49.0 * floor(p * ns.z * ns.z);  //  mod(p,7*7)

  vec4 x_ = floor(j * ns.z);
  vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)

  vec4 x = x_ *ns.x + ns.yyyy;
  vec4 y = y_ *ns.x + ns.yyyy;
  vec4 h = 1.0 - abs(x) - abs(y);

  vec4 b0 = vec4( x.xy, y.xy );
  vec4 b1 = vec4( x.zw, y.zw );

  //vec4 s0 = vec4(lessThan(b0,0.0))*2.0 - 1.0;
  //vec4 s1 = vec4(lessThan(b1,0.0))*2.0 - 1.0;
  vec4 s0 = floor(b0)*2.0 + 1.0;
  vec4 s1 = floor(b1)*2.0 + 1.0;
  vec4 sh = -step(h, vec4(0.0));

  vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
  vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;

  vec3 p0 = vec3(a0.xy,h.x);
  vec3 p1 = vec3(a0.zw,h.y);
  vec3 p2 = vec3(a1.xy,h.z);
  vec3 p3 = vec3(a1.zw,h.w);

  //Normalise gradients
  vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
  p0 *= norm.x;
  p1 *= norm.y;
  p2 *= norm.z;
  p3 *= norm.w;

  // Mix final noise value
  vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
  m = m * m;
  return 42.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1),
  dot(p2,x2), dot(p3,x3) ) );
}

vec3 snoiseVec3( vec3 x ){

  float s  = snoise(vec3( x ));
  float s1 = snoise(vec3( x.y - 19.1 , x.z + 33.4 , x.x + 47.2 ));
  float s2 = snoise(vec3( x.z + 74.2 , x.x - 124.5 , x.y + 99.4 ));
  vec3 c = vec3( s , s1 , s2 );
  return c;

}


vec3 curlNoise( vec3 p ){

  const float e = .1;
  vec3 dx = vec3( e   , 0.0 , 0.0 );
  vec3 dy = vec3( 0.0 , e   , 0.0 );
  vec3 dz = vec3( 0.0 , 0.0 , e   );

  vec3 p_x0 = snoiseVec3( p - dx );
  vec3 p_x1 = snoiseVec3( p + dx );
  vec3 p_y0 = snoiseVec3( p - dy );
  vec3 p_y1 = snoiseVec3( p + dy );
  vec3 p_z0 = snoiseVec3( p - dz );
  vec3 p_z1 = snoiseVec3( p + dz );

  float x = p_y1.z - p_y0.z - p_z1.y + p_z0.y;
  float y = p_z1.x - p_z0.x - p_x1.z + p_x0.z;
  float z = p_x1.y - p_x0.y - p_y1.x + p_y0.x;

  const float divisor = 1.0 / ( 2.0 * e );
  return normalize( vec3( x , y , z ) * divisor );

}

mat3 rotation3dY(float angle) {
  float s = sin(angle);
  float c = cos(angle);

  return mat3(
  c, 0.0, -s,
  0.0, 1.0, 0.0,
  s, 0.0, c
  );
}



void main() {
  float distance = 100.;
  vec3 curlNoisePos = progress * 20. * curlNoise(position);
  float distortionAmount = 0.1;
  curlNoisePos *= 1.0 + distortionAmount * sin(10.0 * position.x);
  vec3 newPos = mix(position, curlNoisePos, progress);

  vec4 mvPos = modelViewMatrix * vec4(rotation3dY(time) * newPos, 1.);

  gl_PointSize = 8. * ( 1. / - mvPos.z);

  gl_Position = projectionMatrix * mvPos;
}

`,
      fragmentShader: `uniform float time;
uniform float progress;
uniform sampler2D sky;
uniform vec4 resolution;
varying vec2 vUv;
varying vec3 vPosition;
varying vec3 vNormal;

float PI = 3.141552653589793238;

void main() {
  float dist = distance(gl_PointCoord, vec2(0.5));
  if (dist > 0.5) {
    discard;
  }
  gl_FragColor = vec4(0., 0., 0., 1.);
}`,
    })
    const circle_mesh = new THREE.Points(circle, circle_material)

    group.add(circle_mesh)
}

function display_model (opts) {
  const particle_geo = new THREE.PlaneGeometry(1, 1)
  let geometry = new THREE.InstancedBufferGeometry()

  let count = 10000
  let min_radius = opts.min_radius
  let max_radius = opts.max_radius

  geometry.instanceCount = count
  geometry.setAttribute('position', particle_geo.getAttribute('position'))
  geometry.index = particle_geo.index

  let pos = new Float32Array(count * 3)

  for (let i = 0; i < count; i++) {
    let theta = Math.random() * 2 * Math.PI
    let r = lerp(min_radius, max_radius, Math.random())

    let x = r * Math.sin(theta)
    let y = (Math.random() - 0.05) * 0.1
    let z = r * Math.cos(theta)

    pos.set([x, y, z], i * 3)
  }

  geometry.setAttribute('pos', new THREE.InstancedBufferAttribute(pos,3, false))

  let material = new THREE.ShaderMaterial({
    side: THREE.DoubleSide,
    uniforms: {
      time: { type: 'f', value: 0 },
      uTexture: { type: 't', value: texture },
      uMouse: { type: 'v3', value: new THREE.Vector3() },
      size: { type: 'f', value: opts.size },
      uColor: { type: 'v3', value: new THREE.Color(opts.color) },
      progress: { type: 'f', value: 0 },
     },
    vertexShader: `uniform float time;
uniform float size;
attribute vec3 pos;
uniform vec3 pointerPos;
uniform float progress;
uniform vec3 uMouse;
varying vec2 vUv;
varying vec3 vPosition;
varying vec3 vNormal;
varying vec3 vWorldPosition;

float PI = 3.141552653589793238;

vec3 mod289(vec3 x)
{
  return x - floor(x * (1.0 / 289.0)) * 289.0;
}

vec4 mod289(vec4 x)
{
  return x - floor(x * (1.0 / 289.0)) * 289.0;
}

vec4 permute(vec4 x)
{
  return mod289(((x*34.0)+10.0)*x);
}

vec4 taylorInvSqrt(vec4 r)
{
  return 1.79284291400159 - 0.85373472095314 * r;
}

vec3 fade(vec3 t) {
  return t*t*t*(t*(t*6.0-15.0)+10.0);
}

// Classic Perlin noise
float cnoise(vec3 P)
{
  vec3 Pi0 = floor(P); // Integer part for indexing
  vec3 Pi1 = Pi0 + vec3(1.0); // Integer part + 1
  Pi0 = mod289(Pi0);
  Pi1 = mod289(Pi1);
  vec3 Pf0 = fract(P); // Fractional part for interpolation
  vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
  vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
  vec4 iy = vec4(Pi0.yy, Pi1.yy);
  vec4 iz0 = Pi0.zzzz;
  vec4 iz1 = Pi1.zzzz;

  vec4 ixy = permute(permute(ix) + iy);
  vec4 ixy0 = permute(ixy + iz0);
  vec4 ixy1 = permute(ixy + iz1);

  vec4 gx0 = ixy0 * (1.0 / 7.0);
  vec4 gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
  gx0 = fract(gx0);
  vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
  vec4 sz0 = step(gz0, vec4(0.0));
  gx0 -= sz0 * (step(0.0, gx0) - 0.5);
  gy0 -= sz0 * (step(0.0, gy0) - 0.5);

  vec4 gx1 = ixy1 * (1.0 / 7.0);
  vec4 gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
  gx1 = fract(gx1);
  vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
  vec4 sz1 = step(gz1, vec4(0.0));
  gx1 -= sz1 * (step(0.0, gx1) - 0.5);
  gy1 -= sz1 * (step(0.0, gy1) - 0.5);

  vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
  vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
  vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
  vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
  vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
  vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
  vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
  vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

  vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
  g000 *= norm0.x;
  g010 *= norm0.y;
  g100 *= norm0.z;
  g110 *= norm0.w;
  vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
  g001 *= norm1.x;
  g011 *= norm1.y;
  g101 *= norm1.z;
  g111 *= norm1.w;

  float n000 = dot(g000, Pf0);
  float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
  float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
  float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
  float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
  float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
  float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
  float n111 = dot(g111, Pf1);

  vec3 fade_xyz = fade(Pf0);
  vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
  vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
  float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x);
  return 2.2 * n_xyz;
}

// Classic Perlin noise, periodic variant
float pnoise(vec3 P, vec3 rep)
{
  vec3 Pi0 = mod(floor(P), rep); // Integer part, modulo period
  vec3 Pi1 = mod(Pi0 + vec3(1.0), rep); // Integer part + 1, mod period
  Pi0 = mod289(Pi0);
  Pi1 = mod289(Pi1);
  vec3 Pf0 = fract(P); // Fractional part for interpolation
  vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
  vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
  vec4 iy = vec4(Pi0.yy, Pi1.yy);
  vec4 iz0 = Pi0.zzzz;
  vec4 iz1 = Pi1.zzzz;

  vec4 ixy = permute(permute(ix) + iy);
  vec4 ixy0 = permute(ixy + iz0);
  vec4 ixy1 = permute(ixy + iz1);

  vec4 gx0 = ixy0 * (1.0 / 7.0);
  vec4 gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
  gx0 = fract(gx0);
  vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
  vec4 sz0 = step(gz0, vec4(0.0));
  gx0 -= sz0 * (step(0.0, gx0) - 0.5);
  gy0 -= sz0 * (step(0.0, gy0) - 0.5);

  vec4 gx1 = ixy1 * (1.0 / 7.0);
  vec4 gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
  gx1 = fract(gx1);
  vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
  vec4 sz1 = step(gz1, vec4(0.0));
  gx1 -= sz1 * (step(0.0, gx1) - 0.5);
  gy1 -= sz1 * (step(0.0, gy1) - 0.5);

  vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
  vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
  vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
  vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
  vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
  vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
  vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
  vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

  vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
  g000 *= norm0.x;
  g010 *= norm0.y;
  g100 *= norm0.z;
  g110 *= norm0.w;
  vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
  g001 *= norm1.x;
  g011 *= norm1.y;
  g101 *= norm1.z;
  g111 *= norm1.w;

  float n000 = dot(g000, Pf0);
  float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
  float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
  float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
  float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
  float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
  float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
  float n111 = dot(g111, Pf1);

  vec3 fade_xyz = fade(Pf0);
  vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
  vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
  float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x);
  return 2.2 * n_xyz;
}


mat3 rotation3dY(float angle) {
  float s = sin(angle);
  float c = cos(angle);

  return mat3(
  c, 0.0, -s,
  0.0, 1.0, 0.0,
  s, 0.0, c
  );
}

float saturate(float x)
{
  return clamp(x, 0.0, 1.0);
}

vec3 curl_noise(vec3 p)
{

  // return curlNoise(p);
  const float step = 0.01;
  float ddx = cnoise(p+vec3(step, 0.0, 0.0)) - cnoise(p-vec3(step, 0.0, 0.0));
  float ddy = cnoise(p+vec3(0.0, step, 0.0)) - cnoise(p-vec3(0.0, step, 0.0));
  float ddz = cnoise(p+vec3(0.0, 0.0, step)) - cnoise(p-vec3(0.0, 0.0, step));

  const float divisor = 1.0 / ( 2.0 * step );
  return ( vec3(ddy - ddz, ddz - ddx, ddx - ddy) * divisor );
}

vec3 fbm_vec3(vec3 p, float frequency, float offset)
{
  return vec3(
  cnoise((p+vec3(offset))*frequency),
  cnoise((p+vec3(offset+20.0))*frequency),
  cnoise((p+vec3(offset-30.0))*frequency)
  );
}

vec3 getOffset (vec3 p) {
  float twist_scale = cnoise(pos) * 0.5 + 0.5;
  vec3 temppos = rotation3dY(time * (0.1 + 0.5 * twist_scale) + length(pos.xz)) * p;
  vec3 offset = fbm_vec3(pos, 0.5, 0.);
  return offset * .1;
}

void main() {
  vUv = position.xy + vec2(0.5);
  vec3 finalPos = pos + position * 0.1;

  float particle_size = cnoise(pos * 2.) * .05 + .05;

  vec3 world_pos = rotation3dY(time * 0.3 * (0.1 + 0.5 * particle_size)) * pos;
  vec3 offset0 = getOffset(world_pos);
  vec3 offset = fbm_vec3(world_pos + offset0, 0., 0.);
  vec3 final_mix = offset + progress * world_pos * 10.;
  vec3 particle_position = (modelMatrix * vec4(world_pos + offset + final_mix, 1.)).xyz;

  // Mouse interactions
  vec3 dir = particle_position - uMouse;
  float distance_to_mouse = pow(1. - clamp(length(uMouse.xz - particle_position.xz) -0.3, 0., 1.), 2.);
  particle_position = mix(particle_position, uMouse + normalize(dir) * 0.2, distance_to_mouse);

  // Add position
  vec4 view_pos = viewMatrix * vec4(particle_position, 1.);

  view_pos.xyz += position * size * (0.01 + 0.1 * particle_size);
  gl_Position = projectionMatrix * view_pos;
}

`,
    fragmentShader: `uniform float time;
uniform float progress;
uniform vec3 uColor;

uniform sampler2D uTexture;
uniform vec4 resolution;
varying vec2 vUv;
varying vec3 vPosition;
varying vec3 vNormal;

float PI = 3.141552653589793238;

void main() {
  vec4 ttt = texture2D(uTexture, vUv);
  gl_FragColor = vec4(uColor, ttt.r);
}`,
    transparent: true,
    depthTest: false
  })

  materials.push(material)

  points = new THREE.Mesh(geometry, material)

  group.add(points)

  group.position.set(0.5, 0, 0)
  group.rotation.set(0.2, 0, 0.15)
  scene.add(group)
}



function add_plane () {
  plane_mesh = new THREE.Mesh(
    new THREE.PlaneGeometry(50, 50),
    new THREE.MeshBasicMaterial({side: THREE.DoubleSide,})
  )
  plane_mesh.rotation.set(-Math.PI / 2.5, -0.2, -1)

  scene.add(plane_mesh)
  plane_mesh.visible = false
}

function on_pointer_move(event) {
  pointer.x = (event.clientX / window.innerWidth) * 2 - 1
  pointer.y = - (event.clientY / window.innerHeight) * 2 + 1
}


function animate() {
  const elapsed_time = clock.getElapsedTime()
  time = elapsed_time * 2

  materials.forEach(m => {
    m.uniforms.time.value = time / 2
  })

  group.children[0].material.uniforms.time.value = time * 0.05
  galaxy_mesh.rotation.y = time * 0.005
  const cameraX = - pointer.x
  const cameraY =  pointer.y
  scene.position.x += (cameraX * 0.25 - scene.position.x) / 40
  scene.position.y += (cameraY * 0.25 - scene.position.y) / 40
  requestAnimationFrame(animate)

  render()
}

function render() {
  renderer.render(scene, camera)

  // update the picking ray with the camera and pointer position
  raycaster.setFromCamera(pointer, camera)

  // calculate objects intersecting the picking ray
  const intersects = raycaster.intersectObjects([plane_mesh])
  if (intersects.length > 0) {
    materials.forEach(m => {
      m.uniforms.uMouse.value = intersects[0].point
    })
  }
}

function onWindowResize() {
  if (window.innerWidth <= 786)  camera.position.set(0, 0, 5)
  else camera.position.set(0, 0, 3.5)

  camera.aspect = canvas.offsetWidth / canvas.offsetHeight
  camera.updateProjectionMatrix()
  renderer.setSize(canvas.offsetWidth, canvas.offsetHeight)
}

function create_object_on_load () {
  gsap.to(progress, {
    value: 0,
    ease: 'POWER4.easeOut',
    duration: 3,
    onUpdate: function () {
      group.children[0].material.uniforms.progress.value = progress.value
      materials[0].uniforms.progress.value = progress.value
      materials[1].uniforms.progress.value = progress.value
    }
  })
}

export { init, animate }
