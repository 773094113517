import { configureStore } from '@reduxjs/toolkit'
import image from './modules/image'

export const store = configureStore({
  reducer: {
    image
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
