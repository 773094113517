import React, {useEffect, useState} from 'react';
import './dashboard-layout.scss';
import { Actions } from './Actions';
import EXIF from 'exif-js';
import { TopInfo } from './TopInfo';
import { faClose } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {APPEND_FORM_DATA, INIT_FORM_DATA, REMOVE_ITEM_FORM_DATA, SET_FORM} from "../../../store/modules/image";

export const DashboardLayout = ({ children }: React.PropsWithChildren) => {
  // @ts-ignore
  const numberOfImages = [...Array(10).keys()];
  // @ts-ignore
  const { form, image } = useSelector(state => state.image)
  const dispatch = useDispatch();
  const [preview, setPreview] = useState(false);
  const styles = [
    {id: "3d-model", name: "3D Model"},
    {id: "analog-film", name: "Analog Film"},
    {id: "anime", name: "Anime"},
    {id: "cinematic", name: "Cinematic"},
    {id: "comic-book", name: "Book"},
    {id: "digital-art", name: "Art"},
    {id: "enhance", name: "Enhance"},
    {id: "fantasy-art", name: "Fantasy art"},
    {id: "isometric", name: "Isometric"},
    {id: "line-art", name: "Line art"},
    {id: "low-poly", name: "Low poly"},
    {id: "modeling-compound", name: "Modeling compound"},
    {id: "neon-punk", name: "Neon punk"},
    {id: "origami", name: "Origami"},
    {id: "photographic", name: "Photographic"},
    {id: "pixel-art", name: "Pixel art"},
    {id: "tile-texture", name: "Tile texture"}
  ];
  const [isFile, setIsFile] = useState(true);

  useEffect(() => {
    dispatch(INIT_FORM_DATA())
  }, []);

  useEffect(() => {
    if (image) {
      setPreview(true)
      setTimeout(() => {
        const previewElm = document.getElementById('img-preview');
        // @ts-ignore
        image && previewElm.setAttribute('src', `data:image/png;base64,${image}`);
      }, 100)
    }
  }, [image])

  // @ts-ignore
  function handleInput ({ key, value }) {
    dispatch(SET_FORM({key, value}))
    dispatch(APPEND_FORM_DATA({key, value}))
  }

  // @ts-ignore
  function handleFile (input) {
    setPreview(true);
    dispatch(APPEND_FORM_DATA({key: 'image', value: input.target.files[0]}))
    previewImage(input);
  }

  // @ts-ignore
  function previewImage (input) {
    const reader = new FileReader();
    const img = new Image()
    reader.readAsDataURL(input.target.files[0]);
    reader.onload = function (e) {
      img.onload = function() {
        // @ts-ignore
        EXIF.getData(img, function() {
          // @ts-ignore
          const orientation = EXIF.getTag(this, 'Orientation');
          console.log(orientation)
        })
      }
      const previewElm = document.getElementById('img-preview');
      // @ts-ignore
      previewElm.setAttribute('src', e.target.result);
    }
  }

  // @ts-ignore
  function handleRemoveImgPreview () {
    setPreview(false);
    setIsFile(false);
    setTimeout(() => {
      setIsFile(true);
    }, 0);
    dispatch(REMOVE_ITEM_FORM_DATA('image'))
    const previewElm = document.getElementById('img-preview');
    // @ts-ignore
    previewElm.setAttribute('src', '');
  }

  return (
    <div className='dashboard-layout container py-4'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='card shadow-sm border-0'>
            <div className='card-body p-1'>
              <div className='card border-0'>
                <div className='card-body text-center p-4'>
                  {/*<TopInfo />*/}
                  <div className='content-wrapper'>
                    <h1 className='mb-4'>Image generator</h1>
                    <p>
                      Here you can choose to generate your image(s) from another
                      image, or from text.
                      <br/>
                      Either cases, the text is mandatory.
                    </p>

                    <h4>Select image to generate from:</h4>
                    <div className='image-selection'>
                      <label htmlFor="file">Select Image</label>
                      {isFile && <input id='file'
                                        type='file'
                                        onChange={ev => handleFile(ev)} />}
                    </div>

                    {preview &&
                      <div className='img-preview position-relative'>
                        <img id='img-preview' src="" alt="" />
                        <button className='position-absolute'
                                onClick={handleRemoveImgPreview}>
                          <FontAwesomeIcon icon={faClose} className='text-white' />
                        </button>
                      </div>}

                    <h4>Type the text of the image content:</h4>
                    <textarea value={form.prompt}
                              onChange={({target: {value}}) => handleInput({key: 'prompt', value})} />

                    {/* IMAGE NUMBER */}
                    <h4>Select the number of generated images:</h4>
                    <label htmlFor='select-samples'>
                      Select number of images:
                    </label>
                    <select id='select-samples'
                            defaultValue={form.samples}
                            onChange={({target: {value}}) => handleInput({key: 'samples', value})}>
                      {numberOfImages.map(val => (
                        <option key={val} value={val + 1}>{val + 1}</option>
                      ))}
                    </select>
                  </div>

                  {/* STYLE */}
                  <h4>Select image artistic style:</h4>
                  <label htmlFor='select-style'>Select style:</label>
                  <select id='select-style'
                          defaultValue={form.style}
                          onChange={({target: {value}}) => handleInput({key: 'style', value})}>
                    <option value='' disabled>
                      select style
                    </option>
                    {styles.map(({id, name}) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                    <option defaultValue='3d-model'>3d Model</option>
                  </select>

                  <hr/>

                  <div className="parameters-sliders">
                    <h4>Select image generation parameters:</h4>
                    <label htmlFor="">
                      <span>Select how much the generated image should look like the original one:</span>
                      <br/>
                      <span className='d-flex justify-content-between'>
                        <span>less</span>
                        <span>more</span>
                      </span>
                      <input value={form.imageStrength}
                             onChange={({target: {value}}) => handleInput({key: 'imageStrength', value})} type="range" min='0' max='1' step='0.01' />
                    </label>

                    <label htmlFor="">
                      <span>Select how much the generated image should respect the text:</span>
                      <br/>
                      <span className='d-flex justify-content-between'>
                        <span>less</span>
                        <span>more</span>
                      </span>
                      <input value={form.promptStrength}
                             onChange={({target: {value}}) => handleInput({key: 'promptStrength', value})} type="range" min='1' max='35' step='0.1' />
                    </label>
                  </div>

                  <p>
                    <b>Total price for {form.samples} image{form.samples > 1 ? 's' : ''} is {form.samples * 0.001} EGLD</b>
                  </p>

                  <Actions samples={form.samples} />
                </div>
              </div>
            </div>
          </div>
          {/*<div className={styles.transactions}>{children}</div>*/}
        </div>
      </div>
    </div>
  );
};
