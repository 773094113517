import { init, animate } from './core.js'
import { THREE } from './utils'

import { TextureLoader } from 'three'

// NOTE: Core
let texture

function loader (canvas_id) {
  // NOTE: Init Loading Manager
  // This like JS Promises
  // We use Loading Manager to be able to display the load percentage
  const loading_manager =  new THREE.LoadingManager()

  const texture_loader = new TextureLoader(loading_manager)

  texture_loader.load(new URL('../assets/particle.jpg', import.meta.url).href, (t) => {
    texture = t
  })

  // NOTE: Start canvas settings
  loading_manager.onLoad = function () {
    init(canvas_id)
    animate()
  }
}

export { loader, texture}
