import './index.css';
import './assets/sass/theme.scss';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import { store } from './store'
import {Provider} from 'react-redux'

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(<Provider store={store}><App /></Provider>);
