import * as React from 'react';
import {useEffect, useState} from 'react';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { contractAddress } from 'config';
import { refreshAccount, sendTransactions } from 'helpers';
import {useGetAccount, useGetPendingTransactions} from 'hooks';
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import {useDispatch, useSelector} from "react-redux";
import {get_images, STORE_FORM_DATA} from "../../../../store/modules/image";
import { createId } from '@paralleldrive/cuid2';
import './actions-component.scss'

// @ts-ignore
export const Actions = function ({samples}) {
  // @ts-ignore
  const { images, form: { prompt }, fetching, isPaying } = useSelector(state => state.image)
  const dispatch = useDispatch()
  const { hasPendingTransactions } = useGetPendingTransactions();
  const { address } = useGetAccount();
  const [sessionID, setSessionID] = useState(localStorage.getItem('sessionID'));

  const sendPingTransaction = async () => {
    // stringify formData
    dispatch(STORE_FORM_DATA())
    const pingTransaction = {
      value: 1000000000000000 * +samples,
      data: 'ping',
      receiver: contractAddress,
      gasLimit: '60000000'
    };
    await refreshAccount();

    const response = await sendTransactions({
      transactions: pingTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'Processing transaction',
        errorMessage: 'An error has occurred during Ping',
        successMessage: 'Ping transaction successful'
      },
      redirectAfterSign: false
    });
    setSessionID(response.sessionId);
    localStorage.setItem('sessionID', response.sessionId);
  };

  useEffect(() => {
    // @ts-ignore
    dispatch(get_images({address}))

  }, [address])

  function downloadImage(url: RequestInfo) {
    fetch(url, {
      mode : 'no-cors',
    })
      .then(response => response.blob())
      .then(blob => {
        let blobUrl = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        if (typeof url === "string") {
          a.download = createId();
        }
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
  }

  return (
    <div className='generate-action mt-4 justify-content-center'>
      {!hasPendingTransactions && (
        <button disabled={!prompt}
                className='action-btn m-auto'
                onClick={sendPingTransaction}>
          <FontAwesomeIcon icon={faArrowUp} />
          <span>Generate Image</span>
        </button>
      )}
      {sessionID && <LoadingSpinner sessionID={sessionID} />}
      {images.length > 0 && !fetching && !isPaying &&
        <div className='generated-images'>
          <h2>🎉 Here are the generated images 🎉</h2>
          {images?.length && <figure>
            {
              // @ts-ignore
              images.map((name) => (
                <span key={name}>
                  <img src={`/imgs/${address}/${name}`} alt=""/>
                  <br/>
                  <button onClick={ev => downloadImage(`/imgs/${address}/${name}`)}>Download</button>
                </span>
              ))
            }
          </figure>}
        </div>
      }
    </div>
  );
};
